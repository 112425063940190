<template>
  <tbody>
    <template v-if="isLoading">
      <tr
        v-for="i in 10"
        :key="`skeleton-tr-${i}`"
        class="v-data-table__empty-wrapper"
      >
        <td
          v-for="j in cols"
          :key="`skeleton-td-${j}`"
        >
          <v-skeleton-loader
            class="px-0"
            type="list-item"
          />
        </td>
      </tr>
    </template>

    <!-- <template v-else-if="!isLoading && enableAppSelector === true && currentAppUid === ''">
      <tr class="tr-white">
        <td
          :colspan="cols"
          class="text-center td-empty"
        >
          먼저 앱 선택이 필요합니다.
          <br>
          <br>
          <v-btn @click="$bus.$emit('showSelectAppDialog')">
            선택하기
          </v-btn>
        </td>
      </tr>
    </template> -->

    <template v-else-if="!isLoading && requireSelectedManufacturer === true && $store.getters['App/currentManufacturer'].idx <= 0">
      <tr class="tr-white">
        <td
          :colspan="cols"
          class="text-center td-empty"
        >
          먼저 제조사 선택이 필요합니다.
          <br>
          <br>
          <v-btn @click="$bus.$emit('showSelectManufacturerDialog')">
            선택하기
          </v-btn>
        </td>
      </tr>
    </template>

    <template v-else-if="!isLoading && itemsLength === 0">
      <tr class="tr-white">
        <td
          :colspan="cols"
          class="text-center td-empty"
        >
          No records
        </td>
      </tr>
    </template>
  </tbody>
</template>

<script>
import { mapState } from 'vuex'

export default {
  props: {
    isLoading: {
      type: Boolean,
      defalut: false
    },
    cols: {
      type: Number,
      default: 1
    },
    itemsLength: {
      type: Number,
      default: 0
    },
    requireSelectedManufacturer: {
      type: Boolean,
      default: false
    },
    enableAppSelector: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    ...mapState('App', ['currentAppUid'])
  }
}
</script>

<style lang="scss" scoped>
.td-empty {
  padding-top: 160px !important;
  padding-bottom: 160px !important;
}
.tr-white:hover {
  background: none !important;
}
</style>
